.text-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.text-lines-2 {
  @extend .text-lines;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
}

.text-lines-3 {
  @extend .text-lines;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
}

.page-section a {
  color: $primary;
}
