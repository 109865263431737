.block-cta_with_images {
  .banner-image-1 {
    height: 531px;
  }

  .banner-image-2 {
    height: 380px;
  }

  .banner-image-2,
  .banner-image-1 {
    img {
      height: 100% !important;
      object-fit: cover;
    }
  }
}
